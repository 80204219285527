import { useState } from "react";
import React from 'react';


export const ProcedureContext = React.createContext(null);


export default class Procedure {

    constructor(environ){

        this.file = null;
        this.step = 0;
        this.environ = environ;
    }

    goForward(){
        this.step++;
        return this;
    }
    // initialize(){
    //     this.stepNumber = 0;
    //     this.descriptionText = "Scegli un file di caricare usando il form qui sotto.";
    //     this.outputText = "";
    // }

    // async sendFileAndValidate () {
    //     try {
    //         Procedure.SetS3Config('atlas-reply-caricamenti', 'public');
    //         await Storage.put(key, myFile, {});
    //         this.file = null;
    //         setStatusText("File caricato. A breve sarà processato.");
    //         setTimeout(async () => await processFile(), 2000);

    //     } catch (error) {
    //         console.log("Error uploading file: ", error);
    //         setStatusText("File non caricato. Riprovare.");
    //         setTimeout(() => reset(), 2000);
    //     }
    // }





    static SetS3Config(bucket, level, environ) {
        Storage.configure({
            bucket: bucket,
            level: level,
            region: 'eu-west-1',
            identityPoolId: environ == "production" ? "eu-west-1:42e2814a-66c2-4163-9a9d-3b4261e6d671" : "eu-west-1:2e7316cb-916a-46eb-b9b6-a3c99311e48e",
        });
    }
}


// export function useProcedure(){

//     const [procedure,setProcedure] = useState({step: 0});
//     return {
//         procedure,
//         goForward: ()=>setProcedure({...procedure,step: (procedure.step+1)%3})
//     };
// }