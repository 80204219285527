import { Auth } from 'aws-amplify';
import Lambda from 'aws-sdk/clients/lambda';


export const buildLambdaClient = async () => {
    let cc = await Auth.currentCredentials();
    const lambda = new Lambda({
        credentials: Auth.essentialCredentials(cc),
        region: "eu-west-1"
    });
    return lambda
}