import { Authenticator, Button } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { Box, MenuItem, Paper, Select, Stack, Typography, Grid } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Amplify, Storage } from 'aws-amplify';
import React, { useContext, useState, useEffect } from 'react';
import './App.css';
import { MuiFileInput } from 'mui-file-input';
import Lambda from 'aws-sdk/clients/lambda';
import Auth from '@aws-amplify/auth';
import Welcome from './components/welcome';
import Caricatore from './components/caricatore';
import TopBar from './components/topbar';
import Layout, { Forward } from './components/layout';
import Procedure, { ProcedureContext, useProcedure } from './procedure';
const hr = window.location.href
let domain = "localhost";
let environ = "localdev";
if (hr.startsWith("https://cmersenne.atlasreplymonitoring.it")) { domain = "cmersenne.atlasreplymonitoring.it"; environ = "collaudo"; }
else if (hr.startsWith("https://pmersenne.atlasreplymonitoring.it")) { domain = "pmersenne.atlasreplymonitoring.it"; environ = "production"; }

Amplify.configure({
    Auth: {
        region: 'eu-west-1',
        userPoolId: environ == "production" ? 'eu-west-1_OQM9flRgY' : 'eu-west-1_qLdcsOf1X',
        userPoolWebClientId: environ == "production" ? '1livrdka0ad1albrns716rlq1o' : '2m9r64r403tsr5e7nhote5ihnm',
        mandatorySignIn: false,
        authenticationFlowType: 'USER_SRP_AUTH',
        identityPoolId: environ == "production" ? "eu-west-1:42e2814a-66c2-4163-9a9d-3b4261e6d671" : "eu-west-1:2e7316cb-916a-46eb-b9b6-a3c99311e48e",
        // OPTIONAL - Configuration for cookie storage
        // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
        cookieStorage: {
            // // REQUIRED - Cookie domain (only required if cookieStorage is provided)
            domain: domain,
            // domain: 'localhost',
            // // OPTIONAL - Cookie path
            //     path: '/',
            // // OPTIONAL - Cookie expiration in days
            //     expires: 365,
            // // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
            //     sameSite: "strict" | "lax",
            // // OPTIONAL - Cookie secure flag
            // // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
            secure: true
        },
        Storage: {
            AWSS3: {
                bucket: 'atlas-reply-caricamenti', //REQUIRED -  Amazon S3 bucket name
                region: 'eu-west-1', //OPTIONAL -  Amazon service region
                identityPoolId: environ == "production" ? "eu-west-1:42e2814a-66c2-4163-9a9d-3b4261e6d671" : "eu-west-1:2e7316cb-916a-46eb-b9b6-a3c99311e48e",
            }
        }
        // OPTIONAL - customized storage object
        // storage: MyStorage,

        // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'

        // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
        // clientMetadata: { myCustomKey: 'myCustomValue' },

        // OPTIONAL - Hosted UI configuration
        // oauth: {
        //     domain: 'auth.atlasreplymonitoring.it',
        //     // scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin', 'dev.resources.atlasreplymonitoring.it/read','dev.resources.atlasreplymonitoring.it/write'],
        //     scope: ['phone', 'email', 'profile', 'openid', 'dev.resources.atlasreplymonitoring.it/read','dev.resources.atlasreplymonitoring.it/write'],
        //     redirectSignIn: 'http://localhost:3000/',
        //     redirectSignOut: 'http://localhost:3000/logout.html',
        //     responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
        // }
    }
});


const theme = createTheme({
    palette: {
        danger: {
            main: '#e53e3e',
            dark: '#e53e3e'
        },
        primary: {
            main: '#0971f1',
            dark: '#053e85',
        },
        neutral: {
            main: '#64748B',
            contrastText: '#fff',
        },
    }
});


function SetS3Config(bucket, level) {
    Storage.configure({
        bucket: bucket,
        level: level,
        region: 'eu-west-1',
        identityPoolId: environ == "production" ? "eu-west-1:42e2814a-66c2-4163-9a9d-3b4261e6d671" : "eu-west-1:2e7316cb-916a-46eb-b9b6-a3c99311e48e",
    });
}



function App() {
    const [step, setStep] = useState(0);
    const [myFile, setMyFile] = useState(null);
    const [key, setKey] = useState(null);
    const [validationResult, setValidationResult] = useState(null); 

    useEffect(()=>{
        SetS3Config('atlas-reply-caricamenti', 'public')
    },[]);

    
    function initProcedure(){
        setStep(0);
        setMyFile(null);
        setKey(null);
    }
    function resetProcedure(){
        initProcedure();
    }
    function setFileReadyForUpload(file){
        setMyFile(file);
        let tmp_filename = new Date().valueOf().toString() + ".csv";
        setKey(tmp_filename);
        setStep(1);
    }
    function setFileSent() {
        setMyFile(null);
        setStep(2)
    } 
    function setFileNotSent() {
        setMyFile(null);
        setStep(-2);
    }
    function incorporateFileValidation(fileValidationResponse, results){
        // se la validazione è ok vado a step 3
        if (fileValidationResponse) {
            setStep(3)
        } else if (results.n_exists > 0) {
            console.log("Ci sono righe già esistenti.")
            setStep(-3)
        }
        // se la validazione è ko ....?
        else {
            setStep(-3)
        }
    }
    function setFileValidated() {
        setStep(4)
    }
    function setDataLoadingFailure() {
        setMyFile(null)
        setKey(null)
        setStep(-4)
    }
    function setDataLoadingSuccess() {
       setStep(4)
    }
    
    return (

        <ThemeProvider theme={theme}>
            <Authenticator variation="modal" hideSignUp={true}>
                {

                    ({ signOut, user }) => {
                        // console.log("!!!", user);
                        const groups = user?.getSignInUserSession().getAccessToken().payload["cognito:groups"] || [];
                        return (
                            <div className="App" style={{ height: '100vh' }}>
                                <div className="homeContainer" style={{ width: '100vw' }}>
                                    <ProcedureContext.Provider value={{environ, key, step, setStep, myFile, initProcedure, resetProcedure, setFileReadyForUpload, setFileSent, setFileNotSent, incorporateFileValidation, setFileValidated, setDataLoadingFailure, validationResult, setValidationResult, setDataLoadingSuccess}}>
                                        <Layout user={user} signOut={signOut}/>
                                    </ProcedureContext.Provider>

                                    {/* <Stack justifyContent="space-evenly" style={{ width: '100vw', height: '100vh' }}>
                                        <pre>{JSON.stringify({step,myFile,key})}</pre>
                                        <Grid container>
                                            <Grid item xs={12} md={2}>
                                                <img src="https://atlas-public-assets.s3.eu-west-1.amazonaws.com/canvas.png"
                                                    style={{ maxWidth: "180px", maxHeight: "180px", "borderRadius": "5% 60% 5% 60%" }} />
                                            </Grid>
                                            <Grid item xs={12} md={4} sx={{ mb: 2 }}>

                                            </Grid>
                                            <Grid item xs={12} md={4}>&nbsp;</Grid>
                                            <Grid item xs={12} md={4}>&nbsp;</Grid>
                                            <Grid item xs={12} md={4}>

                                                <div style={{ textAlign: "left", width: '35vw', marginBottom: '20px' }}>
                                                    <Welcome user={user} />
                                                </div>
                                                {(groups.includes("descartes") || groups.includes("admin")) ? (
                                                    <Caricatore environ={environ} />

                                                ) : (
                                                    <div style={{ textAlign: "left", width: '35vw' }}>
                                                        <Typography>
                                                            Non sei abilitato a questa funzione. Se hai bisogno di questo accesso contatta un amministratore.
                                                        </Typography>
                                                    </div>

                                                )}

                                                <div>
                                                    <Button color="primary" variant="outlined" onClick={() => signOut()}>Logout</Button>
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} md={4}>&nbsp;</Grid>

                                        </Grid>

                                    </Stack> */}

                                </div>
                                <div style={{ color: 'black', position: 'absolute', left: '5px', bottom: '10px', zIndex: 10000 }}>{(
                                    process.env['REACT_APP_NAME'] && process.env['REACT_APP_VERSION']
                                ) ? (
                                    <span>{process.env['REACT_APP_NAME']}:{process.env['REACT_APP_VERSION']}</span>
                                ) : (<></>)
                                }</div>

                            </div>
                        );

                    }
                }
            </Authenticator>
        </ThemeProvider>

    );
}
export default App;
