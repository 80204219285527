
import { useContext, useEffect } from "react";
import TopBar from "./topbar";
import { Grid, Box, Stack, Typography, Button } from "@mui/material";
import { MuiFileInput } from "mui-file-input";
import { ProcedureContext } from "../procedure";
import { Storage } from 'aws-amplify';
import { buildLambdaClient } from "../lambdaClient";


export const Forward = () => {

    const { step, setStep } = useContext(ProcedureContext);

    return <Button color="error" onClick={() => setStep((step + 1) % 3)}>Avanti</Button>
}

// era StepO1Commands
const FileSelectionStepCommands = () => {
    // Gestisce selzione del file con i dati da validare
    const { myFile, resetProcedure, setFileReadyForUpload, step, setFileSent, setFileNotSent, key } = useContext(ProcedureContext);
    const onFileChange = (e) => {
        if (e === null) {
            resetProcedure();
        } else {
            setFileReadyForUpload(e);
        }
    }
    const sendFile = async () => {
        try {
            let x = await Storage.put(key, myFile, {});
            if (!!x && !!x.key && x.key === key) return true;
            else return false;
        } catch (error) {
            console.error(error)
            return false;
        }
    };
    const onFileSend = async () => {
        let sendFileOutcome = await sendFile();
        if (sendFileOutcome) {
            setFileSent();
        }
        else {
            setFileNotSent();
        }
    }
    return <Stack spacing={2}>
        <MuiFileInput fullWidth value={myFile} onChange={onFileChange}></MuiFileInput>
        {step === 1 && <Button onClick={onFileSend}>Invia</Button>}
    </Stack>
};


// era Step2Commands
const FileValidationStepCommands = () => {
    // Step di validazione dei dati. 

    const { incorporateFileValidation, environ, key, validationResult, setValidationResult } = useContext(ProcedureContext);

    const validateFile = async () => {
        // Implementare logica di validazione
        try {
            let lambdaClient = await buildLambdaClient();
            let pld = { direct: { operation: 'sla', payload: { bucket: 'atlas-reply-caricamenti', key: 'public/' + key } } };
            console.log("Invoco lambda con ", pld)
            let x = await lambdaClient.invoke({
                FunctionName: environ == 'production' ? 'arn:aws:lambda:eu-west-1:745905534821:function:descartes-etl' : 'arn:aws:lambda:eu-west-1:745905534821:function:descartes-etl-collaudo',
                Payload: JSON.stringify(pld)
            }).promise()
            console.log("Lambda result: ", x)
            if (x.StatusCode !== 200) {
                console.error("E' andata male la chiamata alla lambda.");
                return [false, null];
            } else {
                let pld = typeof x.Payload === 'string' ? JSON.parse(x.Payload) : x.Payload;
                console.log(pld);
                try {
                    let body = typeof pld.body === 'string' ? JSON.parse(pld.body) : pld.body;
                    setValidationResult(body);
                    if (parseInt(body.malformed) === 0 && parseInt(body.n_exists) === 0) {
                        console.log("TUTTO OK")
                        return [true, null]
                        // ${body.wellformed} righe corrette e ${body.malformed} righe non corrette.\n
                        // Premere "CONFERMA" per scrivere definitivamente le righe.
                        // `);
                    } else {
                        console.log("Ci sono ERRORI")
                        return [false, body]
                        // setStatusText(`Il file contiene ${body.total} righe.\n
                        // ${body.wellformed} righe corrette e ${body.malformed} righe non corrette.\n
                        // Le righe non corrette sono ${(body.malformed_rows || []).join(", ")}.\n
                        // Premere "CONFERMA" per scrivere definitivamente le righe.
                        // `);
                    }
                } catch (e) {
                    console.log(x.Payload)
                    console.error(e)
                    return [false, null]
                    // setStatusText("Il processamento è andato a buon fine ma poi si è verificato un errore.")
                }
            }
        } catch (error) {
            console.error(error)
            return [false, null]
        }
        // return new Promise((resolve,reject)=>{
        //     setTimeout(() => {resolve(true)}, 1500);
        // });
    }

    useEffect(() => {
        setTimeout(() => {
            (async () => {
                const [outcome, results] = await validateFile()
                incorporateFileValidation(outcome, results) // fa setStep a seconda di outcome e setValidationResult
            })()
        }, 3000);

    }, []);


    return <></>;
};

const fileWritingStepCommands = () => {

}

const ValidationResultOutput = () => {

    const { validationResult } = useContext(ProcedureContext);

    console.log("Validation res per output ---> ", validationResult)
    console.log("N exists --> ", validationResult.n_exists)
    console.log("Malformed --> ", validationResult.malformed)

    if (!validationResult) {
        return <Typography variant="h6">"Si e' verificato un errore nella validazione."</Typography>;

    } else if ((validationResult.malformed === 0) && (validationResult.n_exists === 0)) {
        return <Typography variant="h6">Il file contiene {validationResult.total} righe.<br />
            Tutte le righe sono corrette.<br /></Typography>

    } else if (validationResult.malformed > 0) {
        return <Typography variant="h6">Il file contiene {validationResult.total} righe.<br />
            {validationResult.wellformed} righe corrette e {validationResult.malformed} righe non corrette.<br />
            Le righe non corrette sono: {(validationResult.malformed_rows || []).join(", ")}.<br />
        </Typography>
    } else if (validationResult.n_exists > 0) {
        return <Typography variant="h6">Il file contiene {validationResult.total} righe.<br />
            Sul sistema esistono già dei dati per il mese selezionato ({validationResult.n_exists} righe).<br /></Typography>
    } else {
        return <Typography variant="h6">Il file contiene {validationResult.total} righe.<br />
            {validationResult.wellformed} righe corrette e {validationResult.malformed} righe non corrette.<br />
            Le righe non corrette sono: {(validationResult.malformed_rows || []).join(", ")}.<br />
        </Typography>
    }
}

const ValidationResultInstructions = () => {
    const { validationResult } = useContext(ProcedureContext);

    if (!validationResult) {
        return <Typography variant="h6">ERRORE durante il processo di validazione.<br />Contattare un amministratore.<br /></Typography>;

    } else if (validationResult.malformed === 0 && validationResult.n_exists === 0) {
        return <Typography variant="h6">Premi "CONFERMA E SCRIVI" per scrivere definitivamente le righe.</Typography>;

    } else if (validationResult.malformed > 0) {
        return <Typography variant="h6">Correggere le righe in errore e riprovare.</Typography>;

    } else if (validationResult.n_exists > 0) {
        return <Typography variant="h6">Impossibile proseguire. Contattare un amministratore.<br /></Typography>

    } else {
        return <Typography variant="h6">Correggere le righe in errore e riprovare.</Typography>;
    }
}

const ErrorCommands = () => {
    const { setStep } = useContext(ProcedureContext);
    return <Button onClick={() => setStep(0)}>Ricomincia</Button>
}

// era Step3Commands
const ConfirmAndSendFileStepCommands = () => {
    // da contesto il setter che fa arrivare allo step 4
    const { key, environ, initProcedure, setDataLoadingSuccess, setDataLoadingFailure, validationResult } = useContext(ProcedureContext)
    //handler_conferma_e_scrivi che poi chiama il setter dello step 4
    const confirmAndWrite = async () => {
        try {
            let lambdaClient = await buildLambdaClient();
            let pld = { direct: { operation: 'sla', payload: { bucket: 'atlas-reply-caricamenti', key: 'public/' + key, write_on_db: true } } };
            console.log("Invoco lambda con ", pld)
            let x = await lambdaClient.invoke({
                FunctionName: environ == 'production' ? 'arn:aws:lambda:eu-west-1:745905534821:function:descartes-etl' : 'arn:aws:lambda:eu-west-1:745905534821:function:descartes-etl-collaudo',
                Payload: JSON.stringify(pld)
            }).promise()
            console.log("Lambda result: ", x)
            if (x.StatusCode !== 200) {
                console.error("E' andata male la chiamata alla lambda.");
                return false;
            } else {
                console.log("File scritto con successo")
                return true;
            }
        } catch (error) {
            console.error(error)
            return false;
        }
    }

    const onConfirmed = async () => {
        let confirmed = await confirmAndWrite();
        if (confirmed) {
            setDataLoadingSuccess();
        }
        else {
            setDataLoadingFailure()
        }
    }

    // restituisce il pulsante <<conferma e scrivi>>
    if (!validationResult)
        return <ErrorCommands />
    else if (parseInt(validationResult.malformed) === 0) {
        return <Stack>
            <Button onClick={onConfirmed}>Conferma e Scrivi</Button>
            <Button onClick={initProcedure}>Annulla</Button>
        </Stack>;
    } else {
        return <ErrorCommands />
    }
};



// Sarebbe lo step 4
const DataLoadedOkStepCommands = () => {
    // I dati sono stati caricati correttamente.
    // Logica da implementare
    const { initProcedure } = useContext(ProcedureContext)



    return <Button onClick={initProcedure}>Ricomincia</Button>
}

const DataLoadedKOStepCommands = () => {
    // da implementare. C'è stato un errore durante la scrittura dei dati nel db.
    // deve restituire un fallimento e terminare
    const { initProcedure } = useContext(ProcedureContext)
    return <Button onClick={initProcedure}>Ricomincia</Button>
}

export default function Layout({ user, signOut }) {

    const { step } = useContext(ProcedureContext);


    let descriptionComp = <Typography variant="h1"></Typography>;
    let outputComp = <Typography variant="h1"></Typography>;
    let instructionsComp = <Typography variant="h1"></Typography>;
    let commandsComp = <Typography variant="h1">PARTE PULSANTI</Typography>;

    if (step === 0) {
        //descriptionComp = <Typography variant="h6">Sezione di caricamento di un file.</Typography>;
        instructionsComp = <Typography variant="h6">Scegli un file da caricare usando il form qui sotto.</Typography>;
        commandsComp = <FileSelectionStepCommands />;
    }
    else if (step === 1) {
        descriptionComp = <Typography variant="h6"><p>Il file è stato selezionato.</p></Typography>;
        instructionsComp = <Typography variant="h6"><p>Premi "INVIA" per caricarlo.</p></Typography>
        commandsComp = <FileSelectionStepCommands />;

    }
    else if (step === -2) {
        //TODO: terminare controllo fallito invio file su bucket
        outputComp = <Typography variant="h6">Caricamento file fallito. <br />Contattare un amministratore.<br /></Typography>;
        commandsComp = <ErrorCommands />;
    }
    else if (step === 2) {
        descriptionComp = <Typography variant="h6">Il file è stato inviato. Validazione in corso ...</Typography>;
        commandsComp = <FileValidationStepCommands />;
    }
    else if (step === -3) {
        descriptionComp = <Typography variant="h6">Validazione terminata.</Typography>;
        outputComp = <ValidationResultOutput />;
        instructionsComp = <ValidationResultInstructions />;
        commandsComp = <ErrorCommands />;
        //outputComp = <Typography variant="h6">Errore durante la validazione del file. Contattare un amministratore.</Typography>;
    }
    else if (step === 3) {
        descriptionComp = <Typography variant="h6">Validazione terminata.</Typography>;
        outputComp = <ValidationResultOutput />;
        instructionsComp = <ValidationResultInstructions />;
        commandsComp = <ConfirmAndSendFileStepCommands />;
    }
    // else if (step === 4) {
    //     descriptionComp = <Typography variant="h6">Scrittura dati in corso ...</Typography>;
    //     commandsComp = <FileValidationStepCommands />;
    // }
    else if (step === -4) {
        descriptionComp = <Typography variant="h6">Scrittura fallita</Typography>;
        outputComp = <Typography variant="h6"><p>Si è verificato un ERRORE durante la scrittura dei dati.</p></Typography>;
        instructionsComp = <Typography variant="h6"><p>Contattare un amministratore.</p></Typography>;
        commandsComp = <ErrorCommands />;
    }
    else if (step === 4) {
        descriptionComp = <Typography variant="h6">Caricamento completato.</Typography>;
        outputComp = <Typography variant="h6"><p>Tutti i dati sono stati caricati correttamente.</p></Typography>;
        instructionsComp = <Typography variant="h6"><p>Cliccare su "RICOMINCIA" per caricare un nuovo file.</p></Typography>;
        commandsComp = <DataLoadedOkStepCommands />
    }
    else { }



    return (
        <>
            <TopBar user={user} onLogout={signOut} />
            <Grid container sx={{ width: "99vw", height: "90vh", mt: 1 }}>
                <Grid item xs={2} />
                <Grid item xs={8}>
                    <Stack sx={{ height: "100%" }}>
                        <Box sx={{ width: "100%", height: "10%" }}></Box>
                        <Box sx={{ width: "100%", height: "10%" }}>
                            {descriptionComp}
                        </Box>
                        <Box sx={{ width: "100%", height: "30%" }}>
                            {outputComp}
                        </Box>
                        <Box sx={{ width: "100%", height: "10%" }}>
                            {instructionsComp}
                        </Box>
                        <Box sx={{ width: "100%", height: "20%" }}>
                            {commandsComp}
                        </Box>
                        <Box sx={{ width: "100%", height: "20%" }}></Box>
                    </Stack>
                </Grid>
                <Grid item xs={2} />
            </Grid>
        </>
    );
}